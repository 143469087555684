export function dateToString(date) {
    return `${(date.getFullYear())}-${(date.getMonth() + 1)}-${date.getDate()}`;
}

/**
 * Test whether given input object is an empty object. ({})
 * @param obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
    for(const prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}


export function Authorization(user, roles) {
    let userRoles = user.roles;
    if(roles.some(role=> userRoles.includes(role))) {
        return true;
    }
}

export const formatDate = (date) => {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date(date);
    return today.toLocaleDateString("nl-NL", options);
};